import { A, action, useLocation, createAsync, cache } from "@solidjs/router";
import { createSignal, DEV, onMount } from "solid-js";
import {} from "@solidjs/router";

import { useSearchParams } from "@solidjs/router";
import { identifyByUniqueId } from "~/lib/customer-io";
import delay from "delay";
import { Meta, Title } from "@solidjs/meta";
import { CaptchaFox } from "@captchafox/solid";

import { customerStore, StoredCustomer } from "../lib/user-store";
import { getGlobalConfig, GlobalConfig } from "~/lib/config-global";
import { logException } from "~/lib/error-logger-client";
const [storedCustomer, setStoredCustomer] = customerStore;

export default function Mail() {
  const [subscriptionRequestPending, setSubscriptionRequestPending] =
    createSignal(false);
  const [subscriptionRequestSent, setSubscriptionRequestSent] =
    createSignal(false);
  const [humanToken, setHumanToken] = createSignal("");
  const [isLocalHost, setIsLocalHost] = createSignal(false);

  onMount(() => {
    setIsLocalHost(
      window && window.location && window.location.hostname === "localhost"
    );
  });

  const subscriptionRequestAction = action(async (formData: FormData) => {
    console.log(
      "getGlobalConfig().isCaptchaEnforced",
      getGlobalConfig().isCaptchaEnforced
    );
    if (getGlobalConfig().isCaptchaEnforced) {
      if (humanToken() === "") {
        console.warn("no captcha token found");
        return; // do nothing
      }
    }
    setSubscriptionRequestPending(true);

    const firstName = String(formData.get("firstname"));
    const lastName = String(formData.get("lastname"));
    const email = String(formData.get("email"));

    const response = await fetch("/api/subscription_request", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        humanToken: humanToken(),
      }),
    });
    const result = await response.json();
    if (!result.success) {
      const err = new Error(
        `Failed to POST subscription request with message ${result.message}`
      );
      logException(err, true);
      console.warn(err);
      return;
    }

    setSubscriptionRequestSent(true);

    const user_id = result.data.user_id;

    identifyByUniqueId(user_id);

    setStoredCustomer({
      user_id: user_id,
      first_name: firstName,
      last_name: lastName,
      country_code: "",
      email: email,
      is_loaded: false,
    });

    // Try to detect/update country async
    fetch("https://api.country.is", {
      headers: {
        mode: "cors",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((result) => result.json())
      .then((body) => {
        identifyByUniqueId(user_id, {
          country_code_detected: body.country,
        });
      })
      .catch((e) => {
        logException(e, true);
      });
  }, "subscription-request-action");

  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <>
      <Title>funfun.email by Fun Fun Function</Title>
      <Meta
        name="description"
        content="funfun.email is a weekly-ish newsletter for new data developers, from MPJ, the creator of Fun Fun Function."
      />

      <main class="grid grid-cols-1 place-items-center w-full">
        <div class="grid grid-cols-1 md:grid-cols-2 pt-10 pr-8 pl-8 max-w-screen-xl ">
          {(subscriptionRequestSent() || searchParams.debugVerify) && (
            <div class="px-8 mb-4">
              <p class="text-4xl text-white font-serif my-6 pt-8 pr-2">
                <span class="fff-th">Almost there!</span>
                <br /> You know the drill - you must now venture to your email
                inbox to{" "}
                <span class="fff-th">
                  verify that you indeed own your email
                </span>
                .
              </p>
              <p>
                🚨 It WILL be in your spam folder. Consider protecting{" "}
                <b>mpj@funfun.email</b> in your filtering rules. -{" "}
                <i>Edsel Murphy</i>
              </p>
            </div>
          )}
          {!(subscriptionRequestSent() || searchParams.debugVerify) && (
            <>
              <div class="px-8 mb-4 ">
                <p class="text-4xl text-white font-serif my-6 pt-8 pr-2">
                  <span class="fff-th">funfun.email</span> is a weekly-ish
                  newsletter for new <span class="fff-th">data developers</span>
                  , from MPJ, the creator of{" "}
                  <span class="fff-th">Fun Fun Function</span>.
                </p>

                <form
                  id="subscribe-form"
                  class="pr-10"
                  action={subscriptionRequestAction}
                  method="post"
                >
                  <div class="mb-2">
                    <div class="pb-4"></div>
                    <div class="flex flex-row gap-4">
                      <div class="basis-2/5">
                        <label class="font-serif" for="firstname">
                          First Name
                        </label>
                        <input
                          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="firstname"
                          name="firstname"
                          autocomplete="given-name"
                          type="text"
                          required
                          disabled={subscriptionRequestPending() ? true : false}
                          placeholder="Fluffykins"
                        />
                      </div>
                      <div class="basis-3/5">
                        <label class="font-serif" for="lastname">
                          Last name
                        </label>
                        <input
                          class="shadow  appearance-none border  rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                          id="lastname"
                          name="lastname"
                          autocomplete="family-name"
                          disabled={subscriptionRequestPending() ? true : false}
                          required
                          type="text"
                          placeholder="Dragonborn"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div>
                      <label class="font-serif" for="email">
                        E-mail
                      </label>
                      <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        required
                        disabled={subscriptionRequestPending() ? true : false}
                        placeholder="fluffydragon@hotmail.com"
                      />
                    </div>
                  </div>
                  <div class="flex flex-row gap-4">
                    <div class="basis-3/5">
                      <label class="font-serif pb-4" for="email">
                        Are you a smart human?
                      </label>
                      <CaptchaFox
                        sitekey="sk_OxD7zlbUJI5uWXqJ47ptUh1Ccqzsu"
                        onVerify={(token) => {
                          setHumanToken(token);
                        }}
                      />
                    </div>
                    <div class="basis-2/5">
                      <label class="font-serif pb-4 invisible" for="email">
                        Placeholder
                      </label>
                      <button
                        id="submit-subscribe-form-button"
                        class="fff-button disabled:opacity-75"
                        type="submit"
                        style="width: 100%; height: 55px"
                        disabled={
                          (humanToken() === "" && !isLocalHost()) ||
                          subscriptionRequestPending()
                            ? true
                            : false
                        }
                      >
                        {subscriptionRequestPending() ? "Wait..." : "Subscribe"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="pt-8">
                <div
                  class="invisible md:visible "
                  style=" position: relative;
    width: 100%;
    padding-bottom: 56.25%;"
                >
                  <iframe
                    src="https://www.youtube.com/embed/UVqBBPlZrRw?si=LXDD-SEG5JAaNLPs&rel=0&autoplay=1&mute=1&modestbranding=1&playsinline=1"
                    title="YouTube video player"
                    allow="autoplay; encrypted-media;  picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                    class="shadow-md"
                    style="border-radius: 0.75rem; border:1px solid #009A91; position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; "
                  ></iframe>
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    </>
  );
}
